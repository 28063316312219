import { rem } from 'polished';
import styled, { css } from 'styled-components';

const PhotoListItemContainer = styled.div`
    background-color: ${({ theme }) => theme.base.colors.light};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.default)};
    box-shadow: ${({ theme }) => theme.base.boxShadow.elevationSmall};
    height: 100%;
    overflow: hidden;

    ${({ isCoverPhoto }) =>
        isCoverPhoto &&
        css`
            box-shadow: 0 0 0 5px orange;
        `}
`;

export default PhotoListItemContainer;
