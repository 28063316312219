import { rem } from 'polished';
import styled from 'styled-components';

const BusinessAddressProblemFindingAddressContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;    
    /** Offset margin of button Request support, default size */
    margin-bottom: -${({ theme }) => rem(theme.button.padding.default.v)};
    margin-top: ${rem(-12)};
    padding-bottom: ${rem(16)};
`;

export default BusinessAddressProblemFindingAddressContainer;
