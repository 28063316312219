export const IOS_APP_LINK = 'https://www.apple.com/app-store/';
export const ANDROID_APP_LINK = 'https://play.google.com/store/apps';

export const COLORS = {
    MAIN_DARK_BG: '#482655',
    MAIN_LIGHT_BG: '#F7F1EA',
    ACTIVE_COLOR_BG: '#F15A24',
    ACTIVE_LIGHT_COLOR_BG: '#FEEEE8',

    DARK_COLOR_TEXT: '#482655',
    LIGHT_COLOR_TEXT: '#FFFFFF',
    BORDER_COLOR: '#9D9C9C',
    INPUT_BORDER: '#D9D9E8',
    INPUT_TEXT: '#54547D',
    DISABLED_GREY: '#EBEBEE',
    DISABLED_GREY_DARK: '#9898A5',
};

export const POSTAL_CODE_LENGTH = 5;
export const MOBILE_PHONE_NUMBER_MASK = '(000) 000-0000';

export const BUSINESS_FORM_KEY = 'BUSINESS_FORM_KEY';

export const BUSINESS_LINKS_MAX_COUNT = 6;
export const BUSINESS_PHOTOS_MAX_COUNT = 6;
export const EVENT_PHOTOS_MAX_COUNT = 1;
export const BUSINESS_PHOTOS_FILE_TYPES = ['JPG', 'JPEG', 'PNG', 'GIF', 'HEIC'];

export const BUSINESS_APPROVAL_STATUS_DRAFT = 'DRAFT';
export const BUSINESS_APPROVAL_STATUS_PENDING = 'PENDING';
export const BUSINESS_APPROVAL_STATUS_APPROVED = 'APPROVED';
export const BUSINESS_APPROVAL_STATUS_DENIED = 'DENIED';
export const BUSINESS_APPROVAL_STATUS_NO_PHOTOS = 'NO_PHOTOS';
export const BUSINESS_APPROVAL_STATUS_OUT_OF_BUSINESS = 'OUT_OF_BUSINESS';
export const BUSINESS_APPROVAL_STATUSES = [
    BUSINESS_APPROVAL_STATUS_DRAFT,
    BUSINESS_APPROVAL_STATUS_PENDING,
    BUSINESS_APPROVAL_STATUS_APPROVED,
    BUSINESS_APPROVAL_STATUS_DENIED,
    BUSINESS_APPROVAL_STATUS_NO_PHOTOS,
    BUSINESS_APPROVAL_STATUS_OUT_OF_BUSINESS,
];
export const BUSINESS_STATUS_AS_DRAFT = [
    BUSINESS_APPROVAL_STATUS_DRAFT,
    BUSINESS_APPROVAL_STATUS_NO_PHOTOS,
    BUSINESS_APPROVAL_STATUS_OUT_OF_BUSINESS,
];

export const PHOTO_STATE_DELETED = 'deleted';
export const PHOTO_PREVIEW_CAROUSEL_HEIGHT = 390;

export const RESET_PASSWORD_CODE_LENGHT = 6;

export const INQUIRY_TYPE_INVESTMENT = 'INVESTMENT';
export const INQUIRY_TYPE_ADVERTISING = 'ADVERTISING';
export const INQUIRY_TYPE_EMPLOYMENT = 'EMPLOYMENT';
export const INQUIRY_TYPE_COLLABORATE = 'COLLABORATE';
export const INQUIRY_TYPE_PROMOTE = 'PROMOTE';
export const INQUIRY_TYPE_OTHER = 'OTHER';

export const EDIT_BUSINESS_ACTION_SAVE = 'SAVE';
export const EDIT_BUSINESS_ACTION_UPDATE = 'UPDATE';
export const EDIT_BUSINESS_ACTION_SUBMIT = 'SUBMIT';

export const BUSINESS_LINKS_HTTPS_PROTOCOL = 'https://';
export const BUSINESS_LINKS_HTTP_PROTOCOL = 'http://';
export const BUSINESS_LINKS_REGEX_PROTOCOL = /(^\w+:|^)\/\//;

export const CONTEXT_ENTITY = 'CONTEXT_ENTITY';
export const CONTEXT_PRODUCT = 'CONTEXT_PRODUCT';
export const CONTEXT_EVENT = 'CONTEXT_EVENT';
export const CONTEXT_TYPES = [CONTEXT_ENTITY, CONTEXT_PRODUCT, CONTEXT_EVENT];

export const EDIT_PRODUCT_NAME_MAX_LENGTH = 60;
export const EDIT_PRODUCT_PRICE_MAX_LENGTH = 15;
export const EDIT_PRODUCT_DESCRIPTION_MAX_LENGTH = 2500;
export const SEARCH_ALL_PRODUCTS_MAX_LENGTH = 30;
export const EDIT_SECTION_NAME_MAX_LENGTH = 30;

export const SEARCH_BUSINESSES_ANY_STATUS = 'ANY';

export const ENTITY_TYPE_PEOPLE = 'PEOPLE';
export const ENTITY_TYPE_DESTINATION = 'DESTINATION';
export const ENTITY_TYPE_BUSSINESS = 'BUSINESS';
export const ENTITY_TYPE_RECIPES = 'RECIPES';
export const ENTITY_TYPE_TRIPS = 'TRIPS';
export const ENTITY_ALL_TYPES = 'ALLTYPES';

export const ENTITY_TYPE_ICONS = {
    DESTINATION: 'destination',
    BUSINESS: 'my-businesses',
    PEOPLE: 'people',
    RECIPES: 'recipes',
    TRIPS: 'trips',
};
export const ENTITY_TYPE_PEOPLE_ICON = 'people';
export const ENTITY_TYPE_BUSINESS_ICON = 'my-businesses';

export const INTERESTS_EVENTS = 'events';

export const EVENT_PERFORMER_LABEL = 'Performed by';
export const EVENT_HOST_LABEL = 'Hosted by';
export const EVENT_SPONSOR_LABEL = 'Sponsored by';
export const EVENT_OTHER_LABEL = 'Other';

export const DEVICE_TYPE_APPLE = 'Apple';
export const DEVICE_TYPE_GOOGLE = 'Google';

export const ENTITY_LINK_ACTION_MODAL_MODES = {
    RESEND: 'RESEND',
    REMOVE: 'REMOVE',
};

export const BACKGROUND_MODES = {
    HEART: 'HEART',
    HEART_SIDES_SPACE_BETWEEN: 'HEART_SIDES_SPACE_BETWEEN',
    HEART_SIDES_SPACE_BETWEEN_MOBILE_RIGHT: 'HEART_SIDES_SPACE_BETWEEN_MOBILE_RIGHT',
    HEART_SIDES_SPACE_BETWEEN_FADE: 'HEART_SIDES_SPACE_BETWEEN_FADE',
    HEART_HALF_SIDES_SPACE_BETWEEN: 'HEART_HALF_SIDES_SPACE_BETWEEN',
    TRIANGLE_BOTTOM_SPACE_BETWEEN: 'TRIANGLE_BOTTOM_SPACE_BETWEEN',
    TRIANGLE_FADE_SPACE_BETWEEN: 'TRIANGLE_FADE_SPACE_BETWEEN',
    TRIANGLE_SIDES_MIDDLE_GRADIENT_BOTTOM: 'TRIANGLE_SIDES_MIDDLE_GRADIENT_BOTTOM',
    TRIANGLE_BOTTOM_CENTER: 'TRIANGLE_BOTTOM_CENTER',
};

export const ENTITY_LINK_TO_ENTITY_LIST_MODES = {
    RECEIVED_REQUESTS: 'receivedRequests',
    SENT_REQUESTS: 'sentRequests',
};

export const FLEX_DIRECTION = {
    ROW: 'row',
    COLUMN: 'column',
};

export const CONTACT_FORM_INQUIRY_TYPE_OPTIONS = {
    typeOfInquiry: null,
    investment: INQUIRY_TYPE_INVESTMENT,
    advertising: INQUIRY_TYPE_ADVERTISING,
    employment: INQUIRY_TYPE_EMPLOYMENT,
    collaborate: INQUIRY_TYPE_COLLABORATE,
    other: INQUIRY_TYPE_OTHER,
};

export const VIDEO_IDS = {
    WHAT_IS_FAVE_PAGE: 'FeT4_ocigLc',
    BUILT_TO_HELP_BUSINESS_THRIVE: 'FeT4_ocigLc',
    BECOME_A_PREFERRED_FAVE_PAGE: 'FeT4_ocigLc',
};
