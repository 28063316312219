import { rem } from 'polished';
import styled from 'styled-components';

const PhotoListSortableList = styled.div`
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
    display: flex;
    flex-wrap: wrap;
    padding: ${rem(8)} 0;
    width: 100%;
    gap: 18px;
`;

export default PhotoListSortableList;
