import React from 'react';
import PropTypes from 'prop-types';
import PageHeaderRibbon from './PageHeaderRibbon';
import PageHeaderTitle from './PageHeaderTitle';
import PageHeaderInner from './PageHeaderInner';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { useSelector } from 'react-redux';
import StatusTag from 'components/StatusTag';
import FavePageMainMyBusinessStatusTagContainer from '../../favePageMain/FavePageMainMyBusinessStatusTagContainer';

const PageHeader = ({ title, showStatus }) => {
    const { approval_status: approvalStatus } = useSelector(businessFormSelector);
    return (
        <PageHeaderRibbon>
            <PageHeaderInner>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    {title && <PageHeaderTitle title={title} />}
                    {showStatus && (
                        <FavePageMainMyBusinessStatusTagContainer>
                            <StatusTag status={approvalStatus} />
                        </FavePageMainMyBusinessStatusTagContainer>
                    )}
                </div>
            </PageHeaderInner>
        </PageHeaderRibbon>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string,
    showStatus: PropTypes.bool, 
};

PageHeader.defaultProps = {
    title: undefined,
    showStatus: undefined, 
};

export default PageHeader;
