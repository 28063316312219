import React from 'react';
import PropTypes from 'prop-types';
import { ProgressiveBg } from 'baseline-ui/helpers';
import { Button } from 'baseline-ui/button';
import PhotoListItemContainer from './PhotoListItemContainer';
import PhotoListItemFooter from './PhotoListItemFooter';
import PhotoListItemImageContainer from './PhotoListItemImageContainer';
import PhotoListItemOuter from './PhotoListItemOuter';
import { FormRadio } from 'baseline-ui/form';
import { Field } from 'formik';
import { Col, Row } from 'baseline-ui/layout';
import { FormattedMessage } from 'react-intl';
import { H6 } from 'baseline-ui/typography';

const PhotoListItem = ({
    image,
    onClickEdit,
    onClickDelete,
    radioId,
    radioName,
    radioValue,
    radioLabel,
    isCoverPhoto,
}) => {
    return (
        <PhotoListItemOuter>
            <PhotoListItemContainer isCoverPhoto={isCoverPhoto}>
                <PhotoListItemImageContainer>
                    <ProgressiveBg image={image} />
                </PhotoListItemImageContainer>
                {(onClickEdit || onClickDelete || isCoverPhoto) && (
                    <PhotoListItemFooter>
                        {isCoverPhoto && (
                            <H6 className="cover-photo" color="primary">
                                <FormattedMessage id="addBusiness.page.sections.photos.coverPhoto.label" />
                            </H6>
                        )}
                        <div className="button-group">
                            {onClickEdit && (
                                <Button
                                    btnType="link"
                                    icon="edit"
                                    size="sm"
                                    onClick={onClickEdit}
                                />
                            )}
                            {onClickDelete && (
                                <Button btnType="link" icon="x" size="sm" onClick={onClickDelete} />
                            )}
                        </div>
                    </PhotoListItemFooter>
                )}
                {radioValue && (
                    <Row alignItems={'center'}>
                        <Col>
                            <Field
                                component={FormRadio}
                                id={radioId}
                                name={radioName}
                                value={radioValue}
                            >
                                {radioLabel}
                            </Field>
                        </Col>
                    </Row>
                )}
            </PhotoListItemContainer>
        </PhotoListItemOuter>
    );
};

PhotoListItem.propTypes = {
    image: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
    radioId: PropTypes.string,
    radioName: PropTypes.string,
    radioValue: PropTypes.string,
    radioLabel: PropTypes.string,
    isCoverPhoto: PropTypes.bool,
};

PhotoListItem.defaultProps = {};

export default PhotoListItem;
