import { Modal } from 'baseline-ui/modal';
import SuperAdminCategoryPicker from './SuperAdminCategoryPicker';
import { useState } from 'react';
import { Col, Row } from 'baseline-ui/layout';
import { Button } from 'baseline-ui/button';
import { Small } from 'baseline-ui/typography';

const AddNewCategoryModal = ({ onRequestClose, onAddFilters, filters = [], ...props }) => {
    const [localFilters, setLocalFilters] = useState(filters);
    return (
        <Modal
            title={'Add filter'}
            size="sm"
            handleOnDismiss={onRequestClose}
            showCloseButton={true}
            {...props}
            footerActions={[
                {
                    label: 'Close',
                    onClick: () => onRequestClose(),
                },
                {
                    label: 'Save',
                    onClick: () => {
                        onAddFilters(localFilters);
                        onRequestClose();
                    },
                },
            ]}
        >
            <SuperAdminCategoryPicker
                onSelect={(breadcrumbs) => {
                    setLocalFilters([...localFilters, ...breadcrumbs]);
                }}
            />

            {localFilters.map((filter, filterIndex) => {
                return (
                    <Row
                        key={filter._id}
                        justifyContent={'space-between'}
                        alignItems="center"
                        style={{ padding: 20 }}
                    >
                        <Small>
                            {filter.name}({filter._id})
                        </Small>
                        <Button
                            size="sm"
                            onClick={() => {
                                setLocalFilters(localFilters.filter((f) => f._id !== filter._id));
                            }}
                        >
                            Delete
                        </Button>
                    </Row>
                );
            })}
        </Modal>
    );
};

export default AddNewCategoryModal;
