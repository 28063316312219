import styled from 'styled-components';

const PhotoListItemImageContainer = styled.div`
    cursor: grab;
    user-select: none;
    height: 300px;
    width: 300px;
`;

export default PhotoListItemImageContainer;
