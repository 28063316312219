import { TagSearchCrumbs } from 'api';
import { Button } from 'baseline-ui/button';
import { FormInput } from 'baseline-ui/form';
import FormInputAutocompleteItem from 'baseline-ui/form/src/components/input/FormInputAutocompleteItem';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';

const SuperAdminCategoryPicker = ({ onSelect }) => {
    const [entityAutCompleteItems, setEntityAutocompleteItems] = useState([]);

    const getFieldAutocompleteItems = async (val) => {
        const [data] = await TagSearchCrumbs(val);

        setEntityAutocompleteItems(data);
    };

    const validationSchema = yup.object().shape({
        selectedTag: yup.string().required('Please select a tag'),
    });

    return (
        <Formik
            validationSchema={validationSchema}
            enableReinitialize={true}
            initialValues={{
                selectedTag: '',
            }}
            onSubmit={(values) => {
                onSelect(values.selectedTag);
            }}
        >
            {({ handleSubmit, setFieldValue, values }) => {
                return (
                    <>
                        <Field
                            component={FormInput}
                            label={'Select a filter'}
                            name={'selectedTag'}
                            id={'selectedTag'}
                            type="text"
                            placeholder=""
                            required={false}
                            autoComplete="off"
                            autocompleteItems={entityAutCompleteItems}
                            autocompleteItemRender={(item) => {
                                return (
                                    <FormInputAutocompleteItem
                                        skin="dark"
                                        onClick={() => {
                                            onSelect(item);
                                            setEntityAutocompleteItems([]);
                                        }}
                                    >
                                        {item.reverse().map((i, idx) => (
                                            <>
                                                {i.name} ({i.type}){' '}
                                                {idx === item.length - 1 ? '' : '-> '}
                                            </>
                                        ))}
                                    </FormInputAutocompleteItem>
                                );
                            }}
                            onBlur={() => {
                                setFieldValue('selectedTag', '');
                                // The autocomplete items are reset after 300ms to avoid race condition between onClick event and onBlur.
                                // This probably could be solved by using useEffects, but this is a decent UX as well as simpler to read than a ton of useEffects
                                setTimeout(() => {
                                    setEntityAutocompleteItems([]);
                                }, 300);
                            }}
                            onChange={(e) => {
                                getFieldAutocompleteItems(e.target.value);
                                setFieldValue('selectedTag', e.target.value);
                            }}
                        />
                    </>
                );
            }}
        </Formik>
    );
};

export default SuperAdminCategoryPicker;
