import React from 'react';
import BusinessRequiredInformation from 'screens/BusinessForm/BusinessRequiredInformation';
import {
    ADD_BUSINESS_ACCESS_RIGHTS,
    ADD_BUSINESS_ADDITIONAL_INFORMATION,
    ADD_BUSINESS_CATEGORIES_FILTERS,
    ADD_BUSINESS_LINKS,
    ADD_BUSINESS_PHOTOS,
    ADD_BUSINESS_PRODUCTS,
    ADD_BUSINESS_PEOPLE,
    ADD_BUSINESS_REQUEST_LINK,
    ADD_BUSINESS_REQUIRED_INFORMATION,
    ADD_BUSINESS_EVENTS,
    ADD_BUSINESS_BUSINESSES,
    ADD_BUSINESS_DESTINATION,
    ADD_BUSINESS_REQUEST_LINK_DESTINATION,
    ADD_DESTINATION_REQUEST_LINK_BUSINESS,
    ADD_PROMO_PUSH,
} from 'common/addBusinessViewIds';
import BusinessCategories from 'screens/BusinessForm/BusinessCategories';
import AdditionalInformation from 'screens/BusinessForm/AdditionalInformation';
import Links from 'screens/BusinessForm/Links';
import Photos from 'screens/BusinessForm/Photos';
import AccessRights from 'screens/BusinessForm/AccessRights';
import RequestToLink from 'screens/BusinessForm/RequestToLink';
import addBusinessRequiredInformationSchema from 'schema/addBusinessRequiredInformationSchema';
import ProductsAndServices from 'screens/BusinessForm/ProductsAndServices';
import {
    ENTITY_TYPE_DESTINATION,
    ENTITY_TYPE_BUSSINESS,
    ENTITY_TYPE_PEOPLE,
    ENTITY_LINK_TO_ENTITY_LIST_MODES,
    ENTITY_ALL_TYPES,
} from 'common/consts';
import PersonRequiredInformation from 'screens/PersonForm/PersonRequiredInformation';
import EventsTabs from 'screens/Events/EventMainScreen';
import { isEmpty } from 'lodash';
import EntityLinksToEntity from 'components/EntityLink/EntityLinksToEntity';
import BusinessPromotionalPush from 'routes/BusinessForm/promoPushForm/BusinessPromotionalPush';

const addBusinessSectionsUtils = {
    renderAddBusinessSections: ({ intl, type, isAdmin }) => {
        if (!type) {
            return null;
        }
        switch (type) {
            case ENTITY_TYPE_PEOPLE: {
                return [
                    {
                        id: ADD_BUSINESS_REQUIRED_INFORMATION,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.requiredInformation.button.label',
                        }),
                        component: <PersonRequiredInformation />,
                    },
                    {
                        id: ADD_BUSINESS_CATEGORIES_FILTERS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.categories.button.label',
                        }),
                        component: <BusinessCategories />,
                    },
                    {
                        id: ADD_BUSINESS_ADDITIONAL_INFORMATION,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.additionalInformation.button.label',
                        }),
                        component: <AdditionalInformation />,
                    },
                    {
                        id: ADD_BUSINESS_LINKS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.links.button.label',
                        }),
                        component: <Links />,
                    },
                    {
                        id: ADD_BUSINESS_PHOTOS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.photos.button.label',
                        }),
                        component: <Photos />,
                    },
                    ...(isAdmin
                        ? [
                              {
                                  id: ADD_BUSINESS_EVENTS,
                                  label: intl.formatMessage({
                                      id: 'addBusiness.page.sections.events.button.label',
                                  }),
                                  component: <EventsTabs />,
                              },
                          ]
                        : []),
                    {
                        id: ADD_BUSINESS_PRODUCTS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.productsAndServices.button.label',
                        }),
                        component: <ProductsAndServices />,
                        divider: 'top',
                    },
                    {
                        id: ADD_BUSINESS_REQUEST_LINK,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.requestToLink.button.label',
                        }),
                        component: <RequestToLink entityType={ENTITY_TYPE_BUSSINESS} />,
                        divider: 'top',
                    },
                ];
            }
            case ENTITY_TYPE_BUSSINESS: {
                return [
                    {
                        id: ADD_BUSINESS_REQUIRED_INFORMATION,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.requiredInformation.button.label',
                        }),
                        component: <BusinessRequiredInformation />,
                    },
                    {
                        id: ADD_BUSINESS_CATEGORIES_FILTERS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.categories.button.label',
                        }),
                        component: <BusinessCategories />,
                    },
                    {
                        id: ADD_BUSINESS_ADDITIONAL_INFORMATION,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.additionalInformation.button.label',
                        }),
                        component: <AdditionalInformation />,
                    },
                    {
                        id: ADD_BUSINESS_LINKS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.links.button.label',
                        }),
                        component: <Links />,
                    },
                    {
                        id: ADD_BUSINESS_PHOTOS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.photos.button.label',
                        }),
                        component: <Photos />,
                    },

                    {
                        id: ADD_BUSINESS_ACCESS_RIGHTS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.accessRights.button.label',
                        }),
                        component: <AccessRights />,
                    },
                    ...(isAdmin
                        ? [
                              {
                                  id: ADD_BUSINESS_EVENTS,
                                  label: intl.formatMessage({
                                      id: 'addBusiness.page.sections.events.button.label',
                                  }),
                                  component: <EventsTabs />,
                              },
                              {
                                  id: ADD_BUSINESS_DESTINATION,
                                  label: intl.formatMessage({
                                      id: 'addBusiness.page.sections.destination.button.label',
                                  }),
                                  divider: 'top',
                                  component: (
                                      <EntityLinksToEntity
                                          entityType={ENTITY_ALL_TYPES}
                                          mode={ENTITY_LINK_TO_ENTITY_LIST_MODES.RECEIVED_REQUESTS}
                                      />
                                  ),
                              },
                          ]
                        : []),
                    {
                        id: ADD_BUSINESS_PEOPLE,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.people.button.label',
                        }),
                        component: (
                            <EntityLinksToEntity
                                entityType={ENTITY_TYPE_PEOPLE}
                                mode={ENTITY_LINK_TO_ENTITY_LIST_MODES.RECEIVED_REQUESTS}
                            />
                        ),
                    },
                    {
                        id: ADD_BUSINESS_PRODUCTS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.productsAndServices.button.label',
                        }),
                        component: <ProductsAndServices />,
                    },

                    ...(isAdmin
                        ? [
                              {
                                  id: ADD_BUSINESS_REQUEST_LINK_DESTINATION,
                                  label: intl.formatMessage({
                                      id: 'addBusiness.page.sections.destination.requestToLink.button.label',
                                  }),
                                  component: <RequestToLink entityType={ENTITY_TYPE_DESTINATION} />,
                                  divider: 'top',
                              },
                              {
                                  id: ADD_PROMO_PUSH,
                                  label: intl.formatMessage({
                                      id: 'addBusiness.page.sections.promoPush.button.label',
                                  }),
                                  component: <BusinessPromotionalPush />,
                                  divider: 'top',
                              },
                          ]
                        : []),
                ];
            }
            case ENTITY_TYPE_DESTINATION: {
                return [
                    {
                        id: ADD_BUSINESS_REQUIRED_INFORMATION,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.requiredInformation.button.label',
                        }),
                        component: <BusinessRequiredInformation />,
                    },
                    {
                        id: ADD_BUSINESS_CATEGORIES_FILTERS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.categories.button.label',
                        }),
                        component: <BusinessCategories />,
                    },
                    {
                        id: ADD_BUSINESS_ADDITIONAL_INFORMATION,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.additionalInformation.button.label',
                        }),
                        component: <AdditionalInformation />,
                    },
                    {
                        id: ADD_BUSINESS_LINKS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.links.button.label',
                        }),
                        component: <Links />,
                    },
                    {
                        id: ADD_BUSINESS_PHOTOS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.photos.button.label',
                        }),
                        component: <Photos />,
                    },
                    {
                        id: ADD_BUSINESS_ACCESS_RIGHTS,
                        label: intl.formatMessage({
                            id: 'addBusiness.page.sections.accessRights.button.label',
                        }),
                        component: <AccessRights />,
                    },
                    ...(isAdmin
                        ? [
                              {
                                  id: ADD_BUSINESS_EVENTS,
                                  label: intl.formatMessage({
                                      id: 'addBusiness.page.sections.events.button.label',
                                  }),
                                  component: <EventsTabs />,
                              },
                          ]
                        : []),
                    {
                        id: ADD_BUSINESS_BUSINESSES,
                        label: 'Links',
                        component: (
                            <EntityLinksToEntity
                                entityType={ENTITY_ALL_TYPES}
                                mode={ENTITY_LINK_TO_ENTITY_LIST_MODES.RECEIVED_REQUESTS}
                            />
                        ),
                        divider: 'top',
                    },
                    {
                        id: ADD_DESTINATION_REQUEST_LINK_BUSINESS,
                        label: 'Request to link',
                        component: <RequestToLink entityType={ENTITY_ALL_TYPES} />,
                        divider: 'top',
                    },
                ];
            }
            default: {
                // eslint-disable-next-line no-console
                console.warn(`Unrecognized business section type "${type}".`);
                return null;
            }
        }
    },
    renderAddBusinessSectionComponentById: (sections, sectionId) => {
        if (isEmpty(sections)) {
            return null;
        }
        return sections.find((section) => section.id === sectionId).component;
    },
    addBusinessHasAllRequiredFields: (requiredFields, type) => {
        return addBusinessSectionsUtils.requiredInformationIsValid(requiredFields, type);
    },
    addBusinessMissingRequiredFields: (requiredFields, intl, type) => {
        const validationResults = addBusinessSectionsUtils.requiredInformationValidate(
            requiredFields,
            type,
        );

        const renderMissingFields = [];

        validationResults.forEach((listItem) => {
            renderMissingFields.push(
                intl.formatMessage({ id: `${type}.form.validation.${listItem}.label` }),
            );
        });

        return renderMissingFields;
    },
    requiredInformationIsValid: (information, type) => {
        return addBusinessRequiredInformationSchema(type).isValidSync(information, type);
    },

    requiredInformationValidate: (information, type) => {
        const errorsFieldList = [];
        try {
            addBusinessRequiredInformationSchema(type).validateSync(information, {
                abortEarly: false,
            });
        } catch (error) {
            error.inner.forEach((innerItem) => {
                errorsFieldList.push(innerItem.path);
            });
        }

        return errorsFieldList;
    },
};

export default addBusinessSectionsUtils;
