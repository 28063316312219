import { update } from 'lodash';
import { GetTier, UpdateTier, CreateTier } from '../../../api';
import produce, { current } from 'immer';

export function stateRemoveItem(state, id) {
    // NOTE: The ROOT item cannot be removed.
    if (!id || state === undefined || state === null) {
        return state;
    }
    const filter = (item) => item._id === id;
    const mapped = { ...state };
    if (mapped.resources) {
        mapped.resources = mapped.resources
            .filter((item) => {
                return !filter(item);
            })
            .map((item) => {
                return stateRemoveItem(item, id);
            });
    }
    return mapped;
}

/**
 * Update all matching items in the tree.
 * Local reducer helper.
 */
export function stateUpdateItem(state, id, update) {
    if (!id || state === undefined || state === null) {
        return state;
    }
    const filter = (item) => item._id === id;

    let mapped = { ...state };
    if (filter(mapped)) {
        if (typeof update === 'function') {
            mapped = update(mapped);
        } else if (update && typeof update === 'object') {
            Object.assign(mapped, update);
        }
    }
    if (mapped.resources) {
        mapped.resources = mapped.resources.map((item) => {
            return stateUpdateItem(item, id, update);
        });
    }
    return mapped;
}

export function collapseItemAndChildren(currentState) {
    if (currentState === undefined || currentState === null) {
        return currentState;
    }
    const expandedItems = currentState.filter((it) => it.expanded === true);
    if (expandedItems === undefined) {
        return currentState;
    }
    for (const it of expandedItems) {
        it.expanded = false;
        collapseItemAndChildren(it.resources);
    }
}

export function stateFindFirst(state, id) {
    if (id === '' || id === undefined || id === null || state === undefined || state === null) {
        return undefined;
    }
    const filter = (item) => item._id === id;
    if (filter(state)) {
        return state;
    }
    if (state.resources) {
        for (const item of state.resources) {
            const result = stateFindFirst(item, id);
            if (result !== undefined) {
                return result;
            }
        }
    }
    return undefined;
}

export class WithTiers {
    #tiers;

    #dispatch;

    constructor(tiers, dispatch) {
        this.#tiers = tiers;
        this.#dispatch = dispatch;
    }

    add(id, tierType, data) {
        this.#dispatch({
            type: 'add',
            payload: {
                _id: id,
                tierType,
                data,
            },
        });
    }

    addGroup(data) {
        this.#dispatch({
            type: 'addGroup',
            payload: {
                data,
            },
        });
    }

    collapseAllChildren(id) {
        this.#dispatch({
            type: 'collapseAllChildren',
            payload: {
                _id: id,
            },
        });
    }

    toggleExpand(id) {
        this.#dispatch({
            type: 'expand',
            payload: {
                _id: id,
            },
        });
    }

    update(id, data) {
        this.#dispatch({
            type: 'update',
            payload: {
                _id: id,
                data,
            },
        });
    }

    setEditing(id, editing) {
        this.#dispatch({
            type: 'update',
            payload: {
                _id: id,
                data: {
                    editing: !!editing,
                },
            },
        });
        if (/^new:/.test(id)) {
            this.#dispatch({
                type: 'remove',
                payload: {
                    _id: id,
                },
            });
        }
    }

    async saveItemAsync(data) {
        const { _id } = data;
        const item = stateFindFirst(this.#tiers, _id);
        if (!item) {
            // eslint-disable-next-line no-console
            console.warn(`Item not found: ${_id}`);
            return;
        }
        this.#dispatch({
            type: 'update',
            payload: {
                _id,
                data: {
                    busy: Date.now(),
                },
            },
        });
        let newId = _id;
        if (data.new || /^new:/.test(_id)) {
            const [created, err] = await CreateTier(data);
            if (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                return;
            }
            newId = created.id;
        } else {
            const [, err] = await UpdateTier(data);
            if (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                return;
            }
        }
        this.update(_id, {
            ...data,
            keywords: data.keywords ? data.keywords?.split(',').map((it) => it.trim()) : [],
            _id: newId,
            new: undefined,
            busy: undefined,
            working: '',
            editing: false,
        });
    }

    async populateAsync(id, showHidden) {
        const item = stateFindFirst(this.#tiers, id);
        if (!item) {
            // eslint-disable-next-line no-console
            console.warn(`Item not found: ${id}`);
            return;
        }
        if (item.resources !== undefined) {
            return;
        }
        if (item.busy !== undefined) {
            return;
        }
        // Add empty resources immediatly.
        this.#dispatch({
            type: 'update',
            payload: {
                _id: id,
                data: {
                    busy: Date.now(),
                },
            },
        });
        const [resources, err] = await GetTier({ startingTagId: id, showHidden });
        if (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            return;
        }
        this.update(id, {
            busy: undefined,
            resources: resources ?? [],
        });
    }
}

const defaultOrdering = 100;
const ordering = {
    ROOT: 0,
    group: 10,
    header: 20,
    filter: 30,
    category: 40,
    subcategory: 50,
};

export const parentChildTypes = {
    ROOT: ['group'],
    group: ['category'],
    category: ['subcategory', 'header'],
    subcategory: ['header'],
    header: ['filter'],
    filter: [],
};

export function getValidChildTypes(parentType) {
    return parentChildTypes[parentType] ?? [];
}

export function orderTiers(tiers) {
    const ordered = { ...tiers };
    if (ordered.resources) {
        ordered.resources = ordered.resources.sort((right, left) => {
            const rightOrder = ordering[right];
            const leftOrdering = ordering[left];
            if (rightOrder === undefined && leftOrdering === undefined) {
                return right.localeCompare?.(left) ?? 1;
            }
            return (rightOrder ?? defaultOrdering) - (leftOrdering ?? defaultOrdering);
        });
    }
    return ordered;
}
