import React from 'react';
import { FormattedMessage } from 'react-intl';
import { H2, UL } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';
import { CONTACT_PATH } from 'common/pathnames';
import { useNavigate } from 'react-router-dom';
import { INQUIRY_TYPE_ADVERTISING } from 'common/consts';
import FavePageAsideInfoBannerContainer from './FavePageAsideInfoBannerContainer';
import FavePageAsideInfoBannerFooterContainer from './FavePageAsideInfoBannerFooterContainer';

const FavePageAsideInfoBanner = () => {
    const navigate = useNavigate();

    return (
        <FavePageAsideInfoBannerContainer>
            {/* TODO consider using markdown for more flexibility */}
            <H2 color="secondary">
                <FormattedMessage id="myBusinesses.page.search.info.title" />
            </H2>
            <UL
                list={[
                    'Advertising',
                    'Send Push Notifications',
                    'See Analytics',
                    'Marketing Design',
                ]}
            />
            <FavePageAsideInfoBannerFooterContainer>
                <Button
                    btnType="outlined"
                    type="button"
                    onClick={() =>
                        navigate(CONTACT_PATH, {
                            state: { inquiryTypeOption: INQUIRY_TYPE_ADVERTISING },
                        })
                    }
                >
                    <FormattedMessage id="myBusinesses.page.aside.info.requestInformation.button.label" />
                </Button>
            </FavePageAsideInfoBannerFooterContainer>
        </FavePageAsideInfoBannerContainer>
    );
};

export default FavePageAsideInfoBanner;
