import styled from 'styled-components';
import { cssHelpers } from 'baseline-ui/helpers';

const AddBusinessLinksListItemLabelContainer = styled.span`
    ${cssHelpers.textOverflow}
    display: block;
    user-select: none;
    white-space: normal;
`;

export default AddBusinessLinksListItemLabelContainer;
