import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { Button } from 'baseline-ui/button';
import PageHeader from 'components/pageLayout/PageHeader';
import AdminLeftAsideTemplate from 'components/pageLayout/templates/AdminLeftAsideTemplate';
import FavePageAsideSearch from 'components/FavePageAsideSearch';
import { ThemeContext } from 'styled-components';
import FavePageAsideContainer from 'components/favePageAside/FavePageAsideMyBusinessDesktop/FavePageAsideContainer';
import FavePageMainContainer from 'components/favePageMain/FavePageMainContainer';
import { CREATE_FAVEPAGE } from 'common/pathnames';
import FavePageAsideButtonContainer from 'components/favePageAside/FavePageAsideButtonContainer';
import FavePageAsideInfoBanner from 'components/favePageAside/FavePageAsideInfoBanner';
import MyBusinessListContainer from 'components/myBusinessesList/MyBusinessListContainer';
import MyBusinessListEmpty from 'components/myBusinessesList/MyBusinessListEmpty';
import { RESET_BUSINESS_ACCESS_RIGHTS_START } from 'redux/actions/BusinessAccessRightsActions';
import useProcessError from 'utils/useProcessError';
import { RESET_BUSINESS_FORM_START } from 'redux/actions/BusinessFormActions';
import { businessesSelector } from 'redux/selectors/businessSelectors';
import { ENTITY_TYPE_BUSSINESS } from 'common/consts';
import CommonWrapper from './CommonWrapper';
import MyBusinessesListItem from '../components/myBusinessesList/MyBusinessesListItem';
import {
    GET_BUSINESESS_PROCESS,
    GET_BUSINESSES_START,
    SET_BUSINESS_FILTER_PROCESS,
    SET_BUSINESS_FILTER_START,
} from '../redux/actions/BusinessesActions';
import useWindowSize from 'common/useWindowSize';
import { color } from '@mui/system';
import { backgrounds } from 'polished';

const MyBusinesses = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useContext(ThemeContext);

    const { ownedBusinesses, searchText } = useSelector(businessesSelector);

    useEffect(() => {
        dispatch({ type: GET_BUSINESSES_START });
        dispatch({ type: RESET_BUSINESS_FORM_START });
        dispatch({ type: RESET_BUSINESS_ACCESS_RIGHTS_START });
    }, []);

    useProcessError({ processNames: [GET_BUSINESESS_PROCESS], withFeedback: true });

    const {type, approvalstatus, page, cityLocation, filters, category, subcategory, filter, hasMore} = useSelector(({ BusinessesReducer }) => BusinessesReducer);

    const initialValues = {
        searchFavePages: searchText || '',
        entityStatus: approvalstatus,
        entityType: type || ENTITY_TYPE_BUSSINESS,
        page: 0,
        searchBusinessCity: cityLocation || "",
        category: category || "",
        subcategory: subcategory || "",
        filter: filter || {}
    };

    const onMore = () => {
        dispatch({
            type: SET_BUSINESS_FILTER_START,
            payload: {
                page: page + 1,
                searchQuery: searchText,
                approvalstatus,
                type,
                cityLocation,
                filters
            },
        });
    };

    const { height } = useWindowSize();
    const [sectionsContainerHeight, setSectionsContainerHeight] = useState(height);
    useEffect(() => {
        const handleResize = () => {
            const footerHeight = 370;
            const availableHeight = height - footerHeight;
            const scrollPosition = window.scrollY + height;
            const documentHeight = document.body.offsetHeight;

            if (scrollPosition >= documentHeight) {
                setSectionsContainerHeight(availableHeight);
            } else {
                setSectionsContainerHeight(height -
                    (theme.header.height +
                        theme.site.page.header.height));
            }
        };

        window.addEventListener('scroll', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('scroll', handleResize);
        };
    }, [theme, height]);
    return (
        <CommonWrapper>
            <Formik initialValues={initialValues}>
                {() => {
                    return (
                        <Form noValidate>
                            <AdminLeftAsideTemplate
                                bgStart={theme.site.admin.bgStart}
                                bgEnd={theme.site.admin.bgEnd}
                                banner={
                                    <PageHeader
                                        title={intl.formatMessage({
                                            id: 'myBusinesses.page.title',
                                        })}
                                    />
                                }
                                aside={
                                    <FavePageAsideContainer sectionsContainerHeight={sectionsContainerHeight}>
                                        <FavePageAsideSearch
                                            label={intl.formatMessage({
                                                id: 'myBusinesses.page.search.form.label',
                                            })}
                                            searchType={SET_BUSINESS_FILTER_START}
                                            searchTypeProcess={SET_BUSINESS_FILTER_PROCESS}
                                        />
                                        <FavePageAsideButtonContainer>
                                            <Button
                                                block
                                                skin="primary"
                                                type="button"
                                                onClick={() => navigate(CREATE_FAVEPAGE)}
                                            >
                                                <FormattedMessage id="myBusinesses.page.addBusiness.button.label" />
                                            </Button>
                                        </FavePageAsideButtonContainer>
                                        <FavePageAsideInfoBanner />
                                    </FavePageAsideContainer>
                                }
                                main={
                                    <FavePageMainContainer>
                                        <MyBusinessListContainer>
                                        <FavePageAsideButtonContainer>
                                            <Button
                                                block
                                                 icon="plus"
                                                skin="secondary"
                                                type="button"
                                                onClick={() => navigate(CREATE_FAVEPAGE)}
                                                style={{
                                                    marginBottom: "1rem",
                                                    color: "rgb(72, 38, 85)",
                                                    backgroundColor: "rgb(255, 255, 255)",
                                                    backgroundImage: "linear-gradient(90deg, rgb(254, 245, 246) 0%, rgb(242, 237, 246) 50%, rgb(242, 246, 255) 100%)",
                                                    borderRadius: "1rem",
                                                    border: "none",
                                                    width: '50%',
                                                    justifySelf: 'center'
                                                }}
                                            >
                                                <FormattedMessage id="myBusinesses.page.addBusiness.button.label" />
                                            </Button>
                                        </FavePageAsideButtonContainer>

                                            <MyBusinessListEmpty
                                                searchType={SET_BUSINESS_FILTER_START}
                                            />
                                            {ownedBusinesses?.length > 0 && ownedBusinesses?.map((payload) => {
                                                const { _id } = payload;
                                                return (
                                                    <MyBusinessesListItem
                                                        business={payload}
                                                        key={`business_list_${_id}`}
                                                    />
                                                );
                                            })}
                                            {
                                                ownedBusinesses?.length > 0 && hasMore && (
                                                    <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                                                        <Button skin="primary" type="button" onClick={onMore}>
                                                            <FormattedMessage id="myBusinesses.page.more.button.label" />
                                                        </Button>
                                                    </div>   
                                                )
                                            }
                                        </MyBusinessListContainer>
                                    </FavePageMainContainer>
                                }
                            />
                        </Form>
                    );
                }}
            </Formik>
        </CommonWrapper>
    );
};

export default MyBusinesses;
