import { FormRadioButton } from 'baseline-ui/form';
import CheckboxIndicator from 'baseline-ui/form/src/components/checkbox/CheckboxIndicator';
import CheckboxInput from 'baseline-ui/form/src/components/checkbox/CheckboxInput';
import CheckboxLabel from 'baseline-ui/form/src/components/checkbox/CheckboxLabel';
import FormCheckboxLabel from 'baseline-ui/form/src/components/checkbox/FormCheckboxLabel';
import { Col, Row } from 'baseline-ui/layout';
import { Modal } from 'baseline-ui/modal';
import { useEffect, useState } from 'react';

const SORT_FIELDS = ['GEO', 'BM25', 'UPDATED', 'CREATED', 'TOTALFAVES', 'LASTFAVEDTIME'];

const SetSortFieldsModal = ({
    onRequestClose,
    sortOrders,
    sortFields,
    onSetSortFields,
    ...props
}) => {
    const [selectedFields, setSelectedFields] = useState([]);
    const skin = 'dark';

    useEffect(() => {
        const sortFieldsArray = sortFields?.split(',') || [];
        const sortOrdersArray = sortOrders?.split(',') || [];
        const selectedFields = sortFieldsArray.map((field, index) => {
            return {
                name: field.toUpperCase(),
                order: sortOrdersArray[index].toUpperCase(),
            };
        });
        setSelectedFields(selectedFields);
    }, [sortFields, sortOrders]);

    return (
        <Modal
            title={'Add sort fields'}
            size="sm"
            handleOnDismiss={onRequestClose}
            showCloseButton={true}
            {...props}
            footerActions={[
                {
                    label: 'Close',
                    onClick: () => onRequestClose(),
                },
                {
                    label: 'Save',
                    onClick: () => {
                        onSetSortFields(selectedFields);
                        onRequestClose();
                    },
                },
            ]}
        >
            {SORT_FIELDS.map((field) => {
                const selectedField = selectedFields.find((sf) => sf.name === field);
                return (
                    <Row justifyContent={'space-between'}>
                        <Col xs={15}>
                            <FormCheckboxLabel hideLabel={true}>
                                <Col>
                                    <CheckboxInput
                                        sizeValue={10}
                                        id={field}
                                        name={field}
                                        type="checkbox"
                                        onChange={() => {
                                            if (selectedField) {
                                                setSelectedFields(
                                                    selectedFields.filter(
                                                        (selectedField) =>
                                                            selectedField.name !== field,
                                                    ),
                                                );
                                                return;
                                            } else {
                                                setSelectedFields([
                                                    ...selectedFields,
                                                    { name: field, order: 'ASC' },
                                                ]);
                                            }
                                        }}
                                        value={field}
                                        checked={selectedField !== undefined}
                                    />
                                </Col>
                                <CheckboxIndicator
                                    sizeValue={'lg'}
                                    skin={skin}
                                    checked={selectedField !== undefined}
                                />

                                <CheckboxLabel sizeValue={'lg'} skin={skin}>
                                    {field}
                                </CheckboxLabel>
                            </FormCheckboxLabel>
                        </Col>
                        <Col>
                            ASC{' '}
                            <input
                                type="radio"
                                name={`${field}_order`}
                                value="ASC"
                                checked={selectedField?.order === 'ASC'}
                                onChange={() => {
                                    setSelectedFields(
                                        selectedFields.map((sf) => {
                                            if (sf.name === field) {
                                                return { ...sf, order: 'ASC' };
                                            } else {
                                                return sf;
                                            }
                                        }),
                                    );
                                }}
                            />
                        </Col>
                        <Col>
                            DESC{' '}
                            <input
                                type="radio"
                                name={`${field}_order`}
                                value="DESC"
                                checked={selectedField?.order === 'DESC'}
                                onChange={() => {
                                    setSelectedFields(
                                        selectedFields.map((sf) => {
                                            if (sf.name === field) {
                                                return { ...sf, order: 'DESC' };
                                            } else {
                                                return sf;
                                            }
                                        }),
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                );
            })}
        </Modal>
    );
};

export default SetSortFieldsModal;
