import { call, put, takeEvery } from 'redux-saga/effects';
import {
    GET_ENTITYPICKER_START,
    GET_ENTITYPICKER_PROCESS,
    GET_ENTITYPICKER_SUCCESS,
    RESET_ENTITYPICKER_START,
} from '../actions/EntityPicker';
import genericSagaHandler from './CommonSaga';
import { GetPublicEntities } from 'api';

function* getEntityPickerSaga({ payload = {} } = {}) {
    yield genericSagaHandler(GET_ENTITYPICKER_PROCESS, function* () {
        const { searchQuery, type, identifier } = payload;
        if (searchQuery) {
            // Search for entities by name.
            const [entities] = yield GetPublicEntities({ searchQuery });
            yield put({
                type: GET_ENTITYPICKER_SUCCESS,
                payload: {
                    identifier,
                    type,
                    searchQuery,
                    entities: entities,
                },
            });
        } else {
            // Reset if no query was given.
            yield put({
                type: RESET_ENTITYPICKER_START,
            });
        }
    });
}

export default function* EntityPickerSaga() {
    yield takeEvery(GET_ENTITYPICKER_START, getEntityPickerSaga);
}
