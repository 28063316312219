import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import { Header, HeaderOffset } from 'baseline-ui/header';
import {useDispatch, useSelector} from 'react-redux';
import { ModalContext } from 'baseline-ui/modal';
import { useIntl } from 'react-intl';
import {
    ADMIN_AREA_PATH,
    HOME_PATH,
    CONTACT_PATH,
    LOGIN_PATH,
    MY_FAVEPAGE_PATH,
    ACCOUNT_PATH,
    CREATE_FAVEPAGE,
    BUSINESS_PATH,
    ABOUT_PATH,
    UPDATES_PATH,
    SIGNUP_PATH,
} from 'common/pathnames';
import { ThemeContext } from 'styled-components';
import { OverlayContext } from 'baseline-ui/overlay';
import {LOGOUT_START} from 'redux/actions/AuthenticationActions';

const SiteHeader = ({ logoPath, logoPathAlt, title, logoWidth, children }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isOpen } = useContext(ModalContext);
    const { isOverlayOpen } = useContext(OverlayContext);
    const user = useSelector(({ AuthenticationReducer }) => AuthenticationReducer._id);
    const { pathname } = useLocation();
    const isAuthenticated = useSelector(({ AuthenticationReducer }) => AuthenticationReducer._id);
    const isAdmin = useSelector(({ AuthenticationReducer }) => AuthenticationReducer.is_admin);

    const theme = useContext(ThemeContext);
    const renderSkinByPath = (itemPath, currentPath) => {
        if (currentPath === itemPath) {
            return 'primary';
        }
        return 'secondary';
    };

    const commonNavigation = [
        {
            label: intl.formatMessage({ id: 'siteHeader.updates.button.label' }),
            path: UPDATES_PATH,
            btnType: 'link',
            skin: renderSkinByPath(UPDATES_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.about.button.label' }),
            path: ABOUT_PATH,
            btnType: 'link',
            skin: renderSkinByPath(ABOUT_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.businesses.button.label' }),
            path: BUSINESS_PATH,
            btnType: 'link',
            skin: renderSkinByPath(BUSINESS_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.inquiry.button.label' }),
            path: CONTACT_PATH,
            btnType: 'link',
            skin: renderSkinByPath(CONTACT_PATH, pathname)
        },
    ];

    const accountOptionsListPublic = [
        {
            label: intl.formatMessage({ id: 'siteHeader.createAccount.button.label' }),
            path: SIGNUP_PATH,
            btnType: 'link',
            skin: renderSkinByPath(SIGNUP_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.signIn.button.label' }),
            path: LOGIN_PATH,
            btnType: 'link',
            skin: renderSkinByPath(LOGIN_PATH, pathname)
        },
    ];

    const accountOptionsListClient = [
        {
            label: intl.formatMessage({ id: 'siteHeader.settings.button.label' }),
            path: ACCOUNT_PATH,
            btnType: 'link',
            skin: renderSkinByPath(ACCOUNT_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.logOut.button.label' }),
            path: '',
            btnType: 'link',
            skin: 'secondary',
            onClick: () => {
                dispatch({
                    type: LOGOUT_START,
                    payload: { navigate },
                });
            },
        },
    ];

    const dropdownItemsClient = [
        {
            label: intl.formatMessage({ id: 'siteHeader.createFavePage.button.label' }),
            skin: renderSkinByPath(CREATE_FAVEPAGE, pathname),
            btnType: 'link',
            path: CREATE_FAVEPAGE,
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.managePages.button.label' }),
            skin: renderSkinByPath(MY_FAVEPAGE_PATH, pathname),
            btnType: 'link',
            path: MY_FAVEPAGE_PATH,
        },
    ];

    const desktopCommonNavigationListPublic = () => {
        return [
            ...commonNavigation,
            {
                label: intl.formatMessage({ id: 'siteHeader.myAccount.button.label' }),
                skin: 'secondary',
                btnType: 'link',
                icon: 'chevron-down',
                iconPostLabel: true,
                dropdownItems: accountOptionsListPublic,
            }
        ];
    }
    const commonNavigationListClient = [
        {
            label: intl.formatMessage({ id: 'siteHeader.updates.button.label' }),
            path: UPDATES_PATH,
            btnType: 'link',
            skin: renderSkinByPath(UPDATES_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.preferred.button.label' }),
            path: BUSINESS_PATH,
            btnType: 'link',
            skin: renderSkinByPath(BUSINESS_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.myFavePage.button.label' }),
            path: MY_FAVEPAGE_PATH,
            btnType: 'link',
            skin: renderSkinByPath(MY_FAVEPAGE_PATH, pathname)
        },
        {
            label: intl.formatMessage({ id: 'siteHeader.help.button.label' }),
            path: CONTACT_PATH,
            skin: renderSkinByPath(CONTACT_PATH, pathname),
            btnType: 'link',
        },
    ];

    
    /**
     * If user is admin, show navigation item Admin page
     */
    if (isAdmin) {
        commonNavigationListClient.push({
            label: intl.formatMessage({ id: 'siteHeader.admin.button.label' }),
            path: ADMIN_AREA_PATH,
            skin: renderSkinByPath(ADMIN_AREA_PATH, pathname),
            btnType: 'link',
        });
    }
    const desktopCommonNavigation = [...commonNavigationListClient];
    const mobileNavigationListClient = [...commonNavigationListClient];
    mobileNavigationListClient.splice(2, 0, ...dropdownItemsClient);
    const desktopCommonNavigationListClient = () => {
        return [
            ...desktopCommonNavigation,
            {
                label: intl.formatMessage({ id: 'siteHeader.myAccount.button.label' }),
                skin: 'secondary',
                btnType: 'link',
                icon: 'chevron-down',
                iconPostLabel: true,
                dropdownItems: accountOptionsListClient
            }
        ];
    }

    const accountOptionsList = {
        public: [...accountOptionsListPublic],
        client: [...accountOptionsListClient],
    };

    const menuModalNavigationList = {
        public: [...commonNavigation],
        client: [...mobileNavigationListClient],
    };
    const navigationList = {
        public: desktopCommonNavigationListPublic(),
        client: desktopCommonNavigationListClient(),
    };
    const isBlurred = isOpen || isOverlayOpen;

    return (
        <>
            <Header
                isFullWidth={false}
                logoPath={logoPath}
                logoNavigateTo={isAuthenticated ? MY_FAVEPAGE_PATH : HOME_PATH}
                logoPathAlt={logoPathAlt}
                logoLabel={intl.formatMessage({ id: 'siteName' })}
                title={title}
                logoWidth={logoWidth}
                navigationList={user ? navigationList.client : navigationList.public}
                menuModalNavigationList={
                    user ? menuModalNavigationList.client : menuModalNavigationList.public
                }
                menuModalSecondaryList={user ? accountOptionsList.client : accountOptionsList.public}
                isBlurred={isBlurred}
                headerColors={['#FFF']}
            />
            <HeaderOffset isBlurred={isBlurred} bgStart={theme.site.admin.bgStart} isFullHeight>
                {children}
            </HeaderOffset>
        </>
    );
};

SiteHeader.propTypes = {
    logoPath: PropTypes.string,
    logoPathAlt: PropTypes.string,
    title: PropTypes.string,
    logoWidth: PropTypes.number,
    children: PropTypes.node.isRequired,
};

SiteHeader.defaultProps = {
    logoPath: undefined,
    logoPathAlt: undefined,
    title: undefined,
    logoWidth: undefined,
};

export default SiteHeader;
