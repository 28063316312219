import { GetTeasersConfig, PutTeasersConfig } from 'api';
import { Button } from 'baseline-ui/button';
import { FieldContainer, Fieldset, FormInput } from 'baseline-ui/form';
import { Col, Row } from 'baseline-ui/layout';
import { Field, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import CommonWrapper from 'screens/CommonWrapper';
import { ModalContext } from 'baseline-ui/modal';
import AddNewCategoryModal from './AddNewCategoryModal';
import SetSortFieldsModal from './SetSortFieldsModal';
import { H4, H5 } from 'baseline-ui/typography';
import { AlertFeedback } from 'baseline-ui/alert';

const TeasersConfiguration = () => {
    const [teasersConfig, setTeasersConfig] = useState([]);
    const { showModal, hideModal } = useContext(ModalContext);

    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const getConfig = async () => {
            const [data] = await GetTeasersConfig();
            console.log(data);
            setTeasersConfig(data);
        };

        getConfig();
    }, []);

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 1000);
        }
    }, [showAlert]);

    return (
        <CommonWrapper>
            <Col>
                {showAlert && (
                    <AlertFeedback
                        inProp={true}
                        alertType={'success'}
                        alertMessage={'Saved successfully'}
                    />
                )}
                <Formik
                    initialValues={teasersConfig}
                    enableReinitialize={true}
                    onSubmit={async (values) => {
                        console.log(values);
                        const form = new FormData();
                        form.append('teasersConfig', JSON.stringify(values.teasers_config));
                        await PutTeasersConfig(form);
                        setShowAlert(true);
                    }}
                >
                    {({ values, setFieldValue, handleSubmit }) => {
                        return (
                            <Col>
                                <Row justifyContent={'space-between'} style={{ padding: 20 }}>
                                    <H4>Teasers</H4>

                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            setFieldValue('teasers_config', [
                                                ...values.teasers_config,
                                                {
                                                    title: undefined,
                                                    queryParams: {
                                                        filters: [],
                                                    },
                                                },
                                            ]);
                                        }}
                                    >
                                        Add new teaser +
                                    </Button>
                                </Row>
                                {values?.teasers_config?.map((teaser, index) => {
                                    const sortFieldsArray =
                                        teaser.queryParams.sortFields?.split(',') || [];
                                    const sortOrdersArray =
                                        teaser.queryParams.sortOrders?.split(',') || [];

                                    return (
                                        <Row
                                            style={{
                                                borderWidth: 1,
                                                borderColor: 'black',
                                                borderStyle: 'solid',
                                                padding: 20,
                                                marginBottom: 20,
                                            }}
                                        >
                                            <Col xxs={30} md={30}>
                                                <Row
                                                    justifyContent={'flex-end'}
                                                    alignItems={'flex-end'}
                                                >
                                                    <Button
                                                        size="sm"
                                                        onClick={() => {
                                                            showModal(AddNewCategoryModal, {
                                                                onRequestClose: () => {
                                                                    hideModal();
                                                                },
                                                                onAddFilters: (filters) => {
                                                                    setFieldValue(
                                                                        `teasers_config[${index}].queryParams.filters`,
                                                                        filters,
                                                                    );
                                                                },
                                                                filters: teaser.queryParams.filters,
                                                            });
                                                        }}
                                                    >
                                                        Add filter
                                                    </Button>
                                                    <Col xs="1" />
                                                    <Button
                                                        size="sm"
                                                        onClick={() =>
                                                            showModal(SetSortFieldsModal, {
                                                                onRequestClose: () => {
                                                                    hideModal();
                                                                },
                                                                onSetSortFields: (sortFields) => {
                                                                    setFieldValue(
                                                                        `teasers_config[${index}].queryParams.sortFields`,
                                                                        sortFields
                                                                            .map((sf) => sf.name)
                                                                            .join(','),
                                                                    );
                                                                    setFieldValue(
                                                                        `teasers_config[${index}].queryParams.sortOrders`,
                                                                        sortFields
                                                                            .map((sf) => sf.order)
                                                                            .join(','),
                                                                    );
                                                                },
                                                                sortFields:
                                                                    teaser.queryParams.sortFields,
                                                                sortOrders:
                                                                    teaser.queryParams.sortOrders,
                                                            })
                                                        }
                                                    >
                                                        Configure sortFields
                                                    </Button>
                                                    <Col xs="1" />
                                                    <Button
                                                        size="sm"
                                                        onClick={() => {
                                                            setFieldValue(
                                                                'teasers_config',
                                                                values.teasers_config.filter(
                                                                    (_, i) => i !== index,
                                                                ),
                                                            );
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Row>
                                                <Fieldset>
                                                    <FieldContainer>
                                                        <H5>Title</H5>
                                                        <Field
                                                            name={`teasers_config[${index}].title`}
                                                            component={FormInput}
                                                            label="Title"
                                                            placeholder="Title"
                                                            skin="dark"
                                                            value={teaser.title}
                                                        />
                                                    </FieldContainer>
                                                    <FieldContainer>
                                                        <H5>Sort Fields and Order</H5>
                                                        <Row>
                                                            <Col xxs={15}>
                                                                {sortFieldsArray.map(
                                                                    (field, index) => {
                                                                        return (
                                                                            <div key={field}>
                                                                                {field} -{' '}
                                                                                {
                                                                                    sortOrdersArray[
                                                                                        index
                                                                                    ]
                                                                                }
                                                                            </div>
                                                                        );
                                                                    },
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </FieldContainer>

                                                    <FieldContainer>
                                                        <H5>Filters</H5>
                                                        {teaser?.queryParams?.filters?.map(
                                                            (filter, filterIndex) => {
                                                                return (
                                                                    <div key={filter._id}>
                                                                        {filter.name} ({filter._id}{' '}
                                                                        )
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                    </FieldContainer>
                                                </Fieldset>
                                            </Col>
                                        </Row>
                                    );
                                })}
                                <Row style={{ padding: 20 }} justifyContent={'flex-end'}>
                                    <Button onClick={handleSubmit}>Save</Button>
                                </Row>
                            </Col>
                        );
                    }}
                </Formik>
            </Col>
        </CommonWrapper>
    );
};

export default TeasersConfiguration;
