import { Button } from 'baseline-ui/button';
import { FieldContainer, FormInput } from 'baseline-ui/form';
import { Col, Row } from 'baseline-ui/layout';
import { BUSINESS_LINKS_HTTPS_PROTOCOL } from 'common/consts';
import { LINK_OTHER } from 'common/linksConstants';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';
import formAddBusinessLinksSchema from 'schema/formAddBusinessLinksSchema';
import businessLinksUtils from 'utils/businessLinksUtils';
import AddBusinessLinksListItemButtonContainer from './AddBusinessLinksListItemButtonContainer';
import AddBusinessLinksListItemContainer from './AddBusinessLinksListItemContainer';
import AddBusinessLinksListItemLabelContainer from './AddBusinessLinksListItemLabelContainer';

const AddBusinessLinksListItem = forwardRef(
    (
        {
            url,
            caption,
            onClick,
            onSave,
            onCancel,
            isFirst,
            isLast,
            allowDrag,
            actionMode = 'view',
            hideCaption,
            cancelable,
            resettable,
            links = [],
        },
        ref,
    ) => {
        const intl = useIntl();
        const [isEditing, setIsEditing] = useState(actionMode != 'view');
        const [editedUrl, setEditedUrl] = useState(url);
        const [editedCaption, setEditedCaption] = useState(caption);
        const [isChanged, setIsChanged] = useState(false);

        const handleEditClick = () => {
            setIsEditing(true);
            setIsChanged(false);
        };

        const handleSaveClick = (validateForm, values, setSubmitting) => {
            validateForm().then((errors) => {
                if (Object.keys(errors).length === 0) {
                    onSave(values.url, values.caption);
                    setSubmitting(false);
                    if (actionMode !== 'edit') {
                        setIsEditing(false);
                    }
                } else {
                    setSubmitting(false);
                }
            });
        };

        const handleResetClick = () => {
            setEditedUrl(url);
            setEditedCaption(caption);
            setIsChanged(false);
        };

        const handleCancelClick = () => {
            setEditedUrl(url);
            setEditedCaption(caption);
            setIsEditing(false);
            onCancel?.(url);
        };

        const handleInputChange = (setter) => (event) => {
            setter(event.target.value);
            setIsChanged(true);
        };

        return (
            <AddBusinessLinksListItemContainer
                isFirst={isFirst}
                isLast={isLast}
                ref={ref}
                allowDrag={allowDrag}
            >
                <Formik
                    initialValues={{ url: editedUrl, caption: editedCaption, type: LINK_OTHER }}
                    validationSchema={formAddBusinessLinksSchema(links)}
                    enableReinitialize
                >
                    {({ validateForm, values, setSubmitting }) => (
                        <Form
                            noValidate
                            style={{ display: 'flex', width: '100%', alignItems: 'center' }}
                        >
                            <div
                                style={{
                                    display: 'grid',
                                    gap: '1rem',
                                    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                                    width: '100%',
                                    alignItems: 'self-start',
                                }}
                            >
                                <FieldContainer>
                                    {isEditing ? (
                                        <Field
                                            component={FormInput}
                                            label={intl.formatMessage({
                                                id: 'addBusiness.page.sections.links.form.url.label',
                                            })}
                                            name="url"
                                            id="url"
                                            type="url"
                                            value={editedUrl}
                                            onChange={handleInputChange(setEditedUrl)}
                                            prependAddon={BUSINESS_LINKS_HTTPS_PROTOCOL}
                                            disabled={false}
                                            autoFocus
                                        />
                                    ) : (
                                        <AddBusinessLinksListItemLabelContainer>
                                            {businessLinksUtils.urlDomainOnly(url)}
                                        </AddBusinessLinksListItemLabelContainer>
                                    )}
                                </FieldContainer>
                                <FieldContainer style={{ display: hideCaption ? 'none' : '' }}>
                                    {isEditing && (
                                        <Field
                                            component={FormInput}
                                            label={intl.formatMessage({
                                                id: 'addBusiness.page.sections.links.form.caption.label',
                                            })}
                                            name="caption"
                                            id="caption"
                                            type="text"
                                            value={editedCaption}
                                            onChange={handleInputChange(setEditedCaption)}
                                            disabled={false}
                                        />
                                    )}
                                </FieldContainer>
                            </div>
                            <div style={{ alignSelf: 'flex-start', marginTop: '0.5rem' }}>
                                <AddBusinessLinksListItemButtonContainer>
                                    {isEditing ? (
                                        <>
                                            <Button
                                                type="submit"
                                                btnType="link"
                                                onClick={() =>
                                                    handleSaveClick(
                                                        validateForm,
                                                        values,
                                                        setSubmitting,
                                                    )
                                                }
                                                disabled={!isChanged}
                                            >
                                                {intl.formatMessage({
                                                    id: 'addBusiness.modal.unsavedChangesSection.footerAction.save.button.label',
                                                })}
                                            </Button>

                                            {resettable && (
                                                <Button
                                                    type="button"
                                                    btnType="link"
                                                    disabled={!isChanged}
                                                    onClick={handleResetClick}
                                                >
                                                    {intl.formatMessage({
                                                        id: 'addBusiness.modal.entityLinkManagement.button.reset.label',
                                                    })}
                                                </Button>
                                            )}

                                            {cancelable && (
                                                <Button
                                                    type="button"
                                                    btnType="link"
                                                    onClick={handleCancelClick}
                                                >
                                                    {intl.formatMessage({
                                                        id: 'addBusiness.modal.entityLinkManagement.button.cancel.label',
                                                    })}
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                type="button"
                                                btnType="link"
                                                icon="edit"
                                                aria-label={intl.formatMessage({
                                                    id: 'addBusiness.page.sections.links.form.editLink.accessibilityLabel',
                                                })}
                                                onClick={handleEditClick}
                                            />
                                            <Button
                                                type="button"
                                                btnType="link"
                                                icon="x"
                                                aria-label={intl.formatMessage({
                                                    id: 'addBusiness.page.sections.links.form.removeLink.accessibilityLabel',
                                                })}
                                                onClick={onClick}
                                            />
                                        </>
                                    )}
                                </AddBusinessLinksListItemButtonContainer>
                            </div>
                        </Form>
                    )}
                </Formik>
            </AddBusinessLinksListItemContainer>
        );
    },
);

AddBusinessLinksListItem.propTypes = {
    url: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    isFirst: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
    allowDrag: PropTypes.bool.isRequired,
    actionMode: PropTypes.oneOf(['view', 'new', 'edit']),
    hideCaption: PropTypes.bool,
    cancelable: PropTypes.bool,
    resettable: PropTypes.bool,
};

export default AddBusinessLinksListItem;
