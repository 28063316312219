import { Button } from 'baseline-ui/button';
import { Col, Row } from 'baseline-ui/layout';
import { Modal } from 'baseline-ui/modal';
import { H2 } from 'baseline-ui/typography';
import { useState } from 'react';

const TierKeywordsModal = ({ show, handleClose, tier, save, ...props }) => {
    const [keywords, setKeywords] = useState(tier.keywords ?? []);
    return (
        <Modal
            onDismiss={handleClose}
            handleOnDismiss={handleClose}
            isDismissible={true}
            onHide={handleClose}
            {...props}
            footerActions={[
                {
                    label: 'Cancel',
                    onClick: handleClose,
                    btnType: 'outlined',
                },
                {
                    label: 'Save',
                    onClick: () => {
                        save(keywords.join(','));
                    },
                },
            ]}
        >
            <H2>
                Keywords for {tier.name} ({tier._id})
            </H2>

            {keywords &&
                keywords?.map?.((keyword, idx) => (
                    <Row key={`keyword_${idx}`} marginBottom={10}>
                        <Col xs={24} justifyContent="flex-start">
                            <input
                                style={{ width: '100%' }}
                                type="text"
                                value={keyword}
                                onChange={(e) => {
                                    const newKeywords = [...keywords];
                                    newKeywords[idx] = e.target.value;
                                    setKeywords(newKeywords);
                                }}
                            />
                        </Col>
                        <Col xs={6} display="flex" padding="0px" justifyContent="flex-end">
                            <Button
                                btnType="outlined"
                                size="sm"
                                onClick={() => {
                                    const newKeywords = [...keywords];
                                    newKeywords.splice(idx, 1);
                                    setKeywords(newKeywords);
                                }}
                            >
                                Delete
                            </Button>
                        </Col>
                    </Row>
                ))}
            <Row justifyContent={'flex-end'}>
                <Button
                    size="sm"
                    onClick={() => {
                        setKeywords([...keywords, '']);
                    }}
                >
                    Add keyword
                </Button>
            </Row>
        </Modal>
    );
};
export default TierKeywordsModal;
