import styled from 'styled-components';

const PhotoListItemFooter = styled.div`
    display: flex;
    align-items: center;
    height: 30px;

    .cover-photo {
        display: flex;
        align-items: center;
        margin-left: 14px;
        font-size: 0.85rem;
        font-weight: bold;
        &::before {
            content: '✔';
            margin-right: 0.4rem;
            color: #333;
        }
    }

    .button-group {
        display: flex;
        margin-left: auto;
    }
`;

export default PhotoListItemFooter;
