import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {Link, useNavigate} from 'react-router-dom';
import { ThemeModeContext } from 'baseline-ui/theme-provider';
import {Container, Row, Col} from 'baseline-ui/layout';
import { AssetRenderer } from 'baseline-ui/helpers';
import FooterContentWrapper from './FooterContentWrapper';
import LogoContainer from './LogoContainer';
import {H5, A} from 'baseline-ui/typography';
import {ABOUT_PATH, HOME_PATH, CONTACT_PATH, TERMS_AND_CONDITIONS_PATH} from 'common/pathnames';
import Separator from 'components/Separator';
import { Button, LinkButton } from 'baseline-ui/button';
import ListWrapper from './ListWrapper';
import BlockWrapper from './BlockWrapper';
import CopyrightContainer from './CopyrightContainer';
import PrivacyTermsWrapper from './PrivacyTermsWrapper';
import ContentContainer from './ContentContainer';
import ContentContainerList from './ContentContainerList';

import ButtonBlock from './ButtonBlock';

const DefaultFooter = ({
    logoPath,
    logoPathAlt,
    logoWidth,
    logoHeight,
    copyrightOwner,
}) => {
    const { themeMode } = useContext(ThemeModeContext);
    const intl = useIntl();
    const navigate = useNavigate();
    return (
        <FooterContentWrapper backgroundColor='light'>
            <Container>
                <Row justifyContent="center">
                    
                    <Col xxs={30}>
                    
                        <ContentContainer>
                        {logoPath && (
                            <LogoContainer>
                                <Link
                                    to="/"
                                    aria-label={intl.formatMessage({
                                        id: 'footer.logo.home.label',
                                    })}
                                >
                                    <AssetRenderer
                                        assetPath={
                                            themeMode === 'darkMode' ? logoPathAlt : logoPath
                                        }
                                        assetWidth={logoWidth}
                                        assetHeight={logoHeight}
                                    />
                                </Link>
                            </LogoContainer>
                    )}
                        <ContentContainerList>
                            <ListWrapper>
                                <A path={ABOUT_PATH}  color='muted' target='_self'>
                                    <FormattedMessage
                                        id="footer.about.label"
                                    />
                                </A>

                                <A path={CONTACT_PATH} color='muted' target='_self'>
                                    <FormattedMessage
                                        id="footer.advertise.label"
                                    />
                                </A>

                                <A path={CONTACT_PATH} color='muted' target='_self'>
                                    <FormattedMessage
                                        id="footer.join.label"
                                    />
                                </A>

                                <A path={CONTACT_PATH} color='muted' target='_self'>
                                    <FormattedMessage
                                        id="footer.opportunities.label"
                                    />
                                </A>

                                <A path={CONTACT_PATH} color='muted' target='_self'>
                                    <FormattedMessage
                                        id="footer.invest.label"
                                    />
                                </A>

                                <A path={CONTACT_PATH} color='muted' target='_self'>
                                    <FormattedMessage
                                        id="footer.contact.label"
                                    />
                                </A>
                            </ListWrapper>
                            <BlockWrapper>
                                <H5 color='muted' textAlign='right' fontWeight={500}>
                                    <FormattedMessage
                                        id="footer.note.label"
                                    />
                                </H5>
                                <ButtonBlock width={225} marginY={8} marginX={1} justifyContent="right" >
                                    <Button
                                        skin='primary'
                                        btnType='default'
                                        block
                                        onClick = {() => navigate(HOME_PATH)}
                                    >
                                        <FormattedMessage
                                            id="footer.button.join.label"
                                        />
                                    </Button>
                                </ButtonBlock>
                            </BlockWrapper>
                            </ContentContainerList>
                        </ContentContainer>
                    </Col>
                </Row>
            </Container>
            <Separator
                borderWidth={0.5}
                margin={{
                    right: 16,
                    left: 16,
                }}
                color='grayLightest'
            />
            <Container>
                <Row justifyContent="center">
                    <Col xxs={30}>
                        <CopyrightContainer>
                            <PrivacyTermsWrapper>
                                <LinkButton to={TERMS_AND_CONDITIONS_PATH} btnType='link' skin='muted' size='sm'>
                                    <FormattedMessage id="footer.terms.label" />
                                </LinkButton>
                                <LinkButton to={"/"} btnType='link' skin='muted' size='sm'>
                                    <FormattedMessage id="footer.privacy.label" />
                                </LinkButton>
                            </PrivacyTermsWrapper>
                        </CopyrightContainer>
                    </Col>
                </Row>
            </Container>
        </FooterContentWrapper>
    );
};

DefaultFooter.propTypes = {
    logoPath: PropTypes.string,
    logoPathAlt: PropTypes.string,
    logoWidth: PropTypes.number,
    logoHeight: PropTypes.number,
    missionTitle: PropTypes.string,
    missionContent: PropTypes.node,
    copyrightOwner: PropTypes.string,
    copyrightCentered: PropTypes.bool,
    details: PropTypes.node,
    isFullWidth: PropTypes.bool,
    showDivider: PropTypes.bool,
};

DefaultFooter.defaultProps = {
    logoPath: undefined,
    logoPathAlt: undefined,
    logoWidth: undefined,
    logoHeight: undefined,
    missionTitle: undefined,
    missionContent: undefined,
    copyrightCentered: false,
    details: undefined,
    isFullWidth: false,
    showDivider: false,
};

export default DefaultFooter;
