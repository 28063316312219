import React from 'react';
import PropTypes from 'prop-types';
import Link from './Link';
import typographyPropTypes from '../prop-types/typographyPropTypes';

const A = ({ color, className, children, path, target }) => {
    return (
        <Link
            style={{ whiteSpace: 'nowrap' }}
            colorValue={color}
            className={className}
            href={path}
            rel={target === '_blank' ? 'noopener' : null}
            target={target}
        >
            {children}
        </Link>
    );
};

A.propTypes = {
    color: typographyPropTypes.typographyColor,
    path: PropTypes.string.isRequired,
    target: typographyPropTypes.linkTarget,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

A.defaultProps = {
    color: undefined,
    target: '_blank',
    className: undefined,
};

export default A;
