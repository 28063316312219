import styled from 'styled-components';
import {media} from 'baseline-ui/layout';
import { rem } from 'polished';


const ContentContainerList = styled.div`
    display: flex;  
    flex-direction: column-reverse;
    justify-content: end;
    width: 100%;

    ${media.md`
        gap: ${rem(24)};
        flex-direction: unset;`}
`;

export default ContentContainerList;
