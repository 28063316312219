import React, { Fragment, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    POST_BUSINESS_PROCESS,
    RESET_BUSINESS_START,
} from '../../redux/actions/OwnABussinessActions';
import {
    Button,
    Checkbox,
    Fab,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useUserStore } from '../../stores/LoginStore';
import LocationFormPart from '../../LocationFormPart';

import CatSubCat from '../../CatSubCat';
import { Formik } from 'formik';
import * as yup from 'yup';
import ManagePhotos from '../../ManagePhotos';
import ReduxLoaderComponent from '../../components/ReduxLoaderComponent';
import ReduxLoaderButton from '../../components/ReduxLoaderButton';
import FastFieldTextInput from '../../components/FastFieldTextInput';
import LinksPanel from '../../components/LinksPanel';
import FavePagePreview from '../../components/FavePagePreview';
import { useState } from 'react';
import RequestSupportModal from '../../components/RequestSupportModal';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
    name: yup
        .string()
        .min(2, 'Business name has to be at least 2 characters')
        .max(30, 'Business name cannot be longer than 30 characters')
        .required(),
    email: yup.string().email('needs to be a valid email').required('email is required'),
    phone: yup
        .string()
        // .matches(phoneRegExp, "Phone number is not valid")
        .required('Phone number is required'),
    privateCell: yup.string().matches(phoneRegExp, 'Private cell number is not valid'),
    ownerName: yup.string().required('Owner name is required'),
    name: yup.string().required('Business name is required'),
    description: yup
        .string()
        .min(3, 'This text has to be at least 3 characters')
        .max(1200, 'This text cannot be more than 1200 characters'),
    website: yup.string().url('Website needs to be a valid url'),
    position: yup
        .string()
        .min(3, 'Position must be more than 3 characters')
        .max(100, 'The position cannot be longer than 100 characters'),
    links: yup.array(
        yup.object().shape({
            type: yup.string().required('Type is required'),
            url: yup.string().url('This is not a valid url').required('Url is required'),
            caption: yup.string().required('Caption is required'),
        }),
    ),
    formattedAddress: yup.string().required(),
    addressFromGoogle: yup
        .string()
        .oneOf(
            [yup.ref('formattedAddress'), null],
            'You have to select the address from the autocomplete dropdown',
        )
        .required('You have to select the address from the autocomplete dropdown'),
    businessMappingDeserialized: yup
        .array()
        .of(yup.object())
        .min(1, 'You need to select at least one category'),
    photos: yup.array().max(6, 'You can attach up to 6 photos'),
});

const BusinessForm = ({ initialValues, onSubmit, isEditing, processName }) => {
    const [showPreview, setShowPreview] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userStore] = useUserStore();
    const postBusinessProcessState = useSelector(
        ({ ProcessReducer }) => ProcessReducer[processName],
    );

    return (
        <Formik
            enableReinitialize={true}
            validateOnChange={false}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                setFieldValue,
                validateField,
                touched,
                isValid,
                errors,
                values,
                dirty,
            }) => (
                <div style={{ display: 'flex', paddingTop: '2.5rem', paddingBottom: 150 }}>
                    <ReduxLoaderComponent processName={POST_BUSINESS_PROCESS} />
                    <Modal open={showPreview} onClose={() => setShowPreview(false)}>
                        <div>
                            <FavePagePreview
                                onClose={() => setShowPreview(false)}
                                links={values.links}
                                photos={values.photos}
                                bussinessName={values.name}
                                address={values.formattedAddress}
                                description={values.description}
                                phone={values.phone}
                                email={values.email}
                            />
                        </div>
                    </Modal>

                    <Grid container spacing={2} justify="space-around">
                        {userStore?.is_admin && (
                            <>
                                <Grid item xs={12}>
                                    <b>Admin Only Fields:</b>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="approvallabel">Approval</InputLabel>
                                        <Select
                                            key={'1'}
                                            labelId="approvallabel"
                                            name="approval"
                                            value={values.approval_status}
                                            onChange={handleChange('approval_status')}
                                            label="Approval"
                                        >
                                            <MenuItem value={'PENDING'}>PENDING</MenuItem>
                                            <MenuItem value={'APPROVED'}>APPROVED</MenuItem>
                                            <MenuItem value={'DENIED'}>DENIED</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="followupStatuslabel">
                                            Follow Up Status
                                        </InputLabel>
                                        <Select
                                            labelId="followupStatuslabel"
                                            name="followupStatus"
                                            value={values.followupStatus}
                                            onChange={handleChange('followupStatus')}
                                            label="Follow up Status"
                                        >
                                            <MenuItem value={'LEFT_MESSAGE'}>Left message</MenuItem>
                                            <MenuItem value={'PITCHED'}>Pitched</MenuItem>
                                            <MenuItem value={'POPULATED'}>Populated</MenuItem>
                                            <MenuItem value={'ADVERTISE'}>Advertise</MenuItem>
                                            <MenuItem value={'BETA'}>Beta</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={2}>
                                    <FastFieldTextInput
                                        name="privateCell"
                                        id="privateCell"
                                        label="Private Cell"
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <b>Advertising:</b>
                                    </Grid>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values['advertise']}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'advertise',
                                                        !values?.advertise,
                                                        true,
                                                    );
                                                }}
                                            />
                                        }
                                        label="Advertise"
                                    />

                                    <TextField
                                        id="advertisementPriority"
                                        label="Ad priority"
                                        variant="outlined"
                                        onChange={(e) => {
                                            setFieldValue(
                                                'advertisementPriority',
                                                e.target.value,
                                                true,
                                            );
                                        }}
                                        value={values['advertisementPriority']}
                                        disabled={!values['advertise']}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <Typography variant={'h3'}>Required information</Typography>
                            <Typography varian={'body'}>
                                Please provide basic information about your business
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FastFieldTextInput
                                key={'2'}
                                name="name"
                                required
                                id="name"
                                label="Business Name"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FastFieldTextInput
                                key={'3'}
                                name="ownerName"
                                required
                                id="ownerName"
                                label="Contact Name"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FastFieldTextInput
                                required
                                type={'phone'}
                                id="phone"
                                name="phone"
                                label="Phone"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FastFieldTextInput
                                required
                                id="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        {
                            <Grid item xs={12}>
                                <LocationFormPart
                                    isEditing={isEditing}
                                    formParameters={values}
                                    businessAddress={values.formattedAddress}
                                    setBusinessAddress={handleChange('formattedAddress')}
                                    setSelectedFromGoogle={handleChange('addressFromGoogle')}
                                    error={
                                        touched['addressFromGoogle'] && errors['addressFromGoogle']
                                            ? errors['addressFromGoogle']
                                            : ''
                                    }
                                />
                            </Grid>
                        }

                        <Grid item xs={12} />

                        <CatSubCat
                            error={errors.businessMappingDeserialized}
                            mainSubCatMapJson={values.featureMapping}
                            fieldMapJson={values.businessMappingDeserialized}
                            setFieldMap={(value) => {
                                setFieldValue(
                                    'businessMappingDeserialized',
                                    JSON.stringify(value),
                                    true,
                                );
                            }}
                        />

                        {!isEditing && (
                            <Grid item xs={12} justifyContent={'flex-end'}>
                                <ReduxLoaderButton
                                    variant="contained"
                                    type="submit"
                                    size="small"
                                    caption={'Save and Finish Later'}
                                    onClick={handleSubmit}
                                    processName={POST_BUSINESS_PROCESS}
                                />

                                {postBusinessProcessState === 'COMPLETE' && (
                                    <Typography
                                        variant="subtitle2"
                                        display="inline"
                                        align="center"
                                        color="gray"
                                    >
                                        &nbsp;&nbsp;&nbsp;Changes Saved
                                    </Typography>
                                )}
                            </Grid>
                        )}
                        {isEditing && <Grid xs={12} item />}
                        {isEditing && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant={'h3'}>Additional information</Typography>
                                    <Typography varian={'body'}></Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FastFieldTextInput
                                        key={'4'}
                                        name="website"
                                        id="website"
                                        label="Business Website"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FastFieldTextInput
                                        key={'5'}
                                        id="position"
                                        name="position"
                                        label="Position"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FastFieldTextInput
                                        key={'6'}
                                        id="description"
                                        label="Description"
                                        name="description"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={10}
                                    />
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <Typography variant={'h3'}>Links</Typography>
                                    <Typography varian={'body'}>
                                        Link to your social media and online presence, up to 6 links
                                    </Typography>
                                </Grid>

                                <LinksPanel
                                    error={errors.links}
                                    links={values.links}
                                    setLinks={(links) => {
                                        setFieldValue('links', links, true);
                                    }}
                                />

                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            const newLinks = JSON.parse(values.links);
                                            newLinks.push({ type: 'other', url: '', caption: '' });
                                            setFieldValue('links', JSON.stringify(newLinks));
                                        }}
                                    >
                                        Add New Link+
                                    </Button>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <Typography variant={'h3'}>Photos</Typography>

                                    <Typography variant={'body1'}>
                                        You can load up to 6 photos. These are going to be shown in
                                        the results as well as in the FavePage image carousel.
                                    </Typography>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            marginTop: 12,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        Problem uploading a photo?{' '}
                                        <RequestSupportModal
                                            formParameters={values}
                                            caption={'Request support'}
                                            defaultType="PHOTOS"
                                            defaultValue="I have a problem uploading photos"
                                        />
                                    </Grid>
                                </Grid>
                                {Array.isArray(values.photos) && (
                                    <ManagePhotos
                                        error={errors.photos}
                                        photos={values.photos}
                                        setPhotos={(photos) => {
                                            setFieldValue('photos', photos, true);
                                        }}
                                    />
                                )}
                                <Grid
                                    container
                                    justifyContent={'space-around'}
                                    alignItems={'center'}
                                    style={{
                                        position: 'fixed',
                                        bottom: 0,
                                        paddingBottom: 10,
                                        zIndex: 8888,
                                        width: '100%',
                                        height: 70,
                                        right: 0,
                                        background: 'white',
                                        borderTopWidth: 1,
                                        borderTopColor: '#e6e6e6',
                                        borderTopStyle: 'solid',
                                        boxShadow: '0px -10px 28px -17px rgba(66, 68, 90, 1)',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            navigate(-1);
                                            dispatch({
                                                type: RESET_BUSINESS_START,
                                            });
                                        }}
                                    >
                                        Back
                                    </Button>

                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setShowPreview(true);
                                        }}
                                    >
                                        Preview
                                    </Button>

                                    <ReduxLoaderButton
                                        caption={'Save'}
                                        onClick={handleSubmit}
                                        processName={POST_BUSINESS_PROCESS}
                                    />

                                    {postBusinessProcessState === 'COMPLETE' && (
                                        <Typography
                                            variant="subtitle2"
                                            display="inline"
                                            align="center"
                                            color="gray"
                                        >
                                            &nbsp;&nbsp;&nbsp;Changes Saved
                                        </Typography>
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </div>
            )}
        </Formik>
    );
};

export default BusinessForm;
